import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const HomePage = lazy(() => import("../pages/homePage/HomePage"));
const Header = lazy(() => import("../components/header/Header"));
const Footer = lazy(() => import("../components/footer/Footer"));
const HowAutoWorks = lazy(() => import("../pages/howAutoWorks/HowAutoWorks"));
const CarShippingCost = lazy(() =>
  import("../pages/carShippingCost/CarShippingCost")
);
const DoorToDoor = lazy(() => import("../pages/doorToDoor/DoorToDoor"));
const OpenCarTransport = lazy(() =>
  import("../pages/openCarTransport/OpenCarTransport")
);
const EnclosedCarTransport = lazy(() =>
  import("../pages/enclosedCarTransport/EnclosedCarTransport")
);
const HawaiiCarTransport = lazy(() =>
  import("../pages/hawaiiCarTransport/HawaiiCarTransport")
);
const AcrossCarShipping = lazy(() =>
  import("../pages/acrossCarShipping/AcrossCarShipping")
);
const ExpeditedCarShipping = lazy(() =>
  import("../pages/expeditedCarShipping/ExpeditedCarShipping")
);
const SeasonalAutoRelocation = lazy(() =>
  import("../pages/seasonalAutoRelocation/SeasonalAutoRelocation")
);
const ClassicCarShipping = lazy(() =>
  import("../pages/classicCarShipping/ClassicCarShipping")
);
const FamiliesAndMovers = lazy(() =>
  import("../pages/familiesAndMovers/FamiliesAndMovers")
);
const StudentsShipping = lazy(() =>
  import("../pages/studentsShipping/StudentsShipping")
);
const MilitaryShipping = lazy(() =>
  import("../pages/militaryShipping/MilitaryShipping")
);
const SnowbirdsShipping = lazy(() =>
  import("../pages/snowbirdsShipping/SnowbirdsShipping")
);
const OnlineBuyers = lazy(() => import("../pages/onlineBuyers/OnlineBuyers"));
const AutoAuction = lazy(() => import("../pages/autoAuction/AutoAuction"));
const AutoDealers = lazy(() => import("../pages/autoDealers/AutoDealers"));
const ProshipReviews = lazy(() =>
  import("../pages/proshipReviews/ProshipReviews")
);
const AboutUs = lazy(() => import("../pages/aboutUs/AboutUs"));
const VisionAndMission = lazy(() =>
  import("../pages/visionAndMission/VisionAndMission")
);
const BlogPage = lazy(() => import("../pages/blogPage/BlogPage"));
const HelpCenter = lazy(() => import("../pages/helpCenter/HelpCenter"));
const Privacy = lazy(() => import("../pages/privacy/Privacy"));
const Terms = lazy(() => import("../pages/terms/Terms"));

const RouteItems = () => {
  return (
    <Suspense>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/how-auto-works" element={<HowAutoWorks />} />
        <Route path="/car-shipping-cost" element={<CarShippingCost />} />
        <Route path="/door-to-door" element={<DoorToDoor />} />
        <Route path="/open-car-transport" element={<OpenCarTransport />} />
        <Route
          path="/enclosed-auto-transport"
          element={<EnclosedCarTransport />}
        />
        <Route path="/hawaii-car-transport" element={<HawaiiCarTransport />} />
        <Route path="/across-car-shipping" element={<AcrossCarShipping />} />
        <Route
          path="/expedited-car-shipping"
          element={<ExpeditedCarShipping />}
        />
        <Route
          path="/seasonal-auto-relocation"
          element={<SeasonalAutoRelocation />}
        />
        <Route path="/classic-car-shipping" element={<ClassicCarShipping />} />
        <Route path="/families-and-movers" element={<FamiliesAndMovers />} />
        <Route path="/students-shipping" element={<StudentsShipping />} />
        <Route path="/military-shipping" element={<MilitaryShipping />} />
        <Route path="/snowbirds-shipping" element={<SnowbirdsShipping />} />
        <Route path="/online-buyers" element={<OnlineBuyers />} />
        <Route path="/auto-auction" element={<AutoAuction />} />
        <Route path="/auto-dealers" element={<AutoDealers />} />
        <Route path="/imperial-review" element={<ProshipReviews />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/vision-and-mission" element={<VisionAndMission />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default RouteItems;
